.estado__btn {
  font-size: 12px;
}

.info__labels {
}

/* sm */
@media (min-width: 576px) { 
  .estado__btn {
    font-size: 14px;
  }
}

/* md */
@media (min-width: 768px) { 
  .estado__btn {
    font-size: 16px;
  }
}


/* lg */
@media (min-width: 992px) {  }


/* xl */
@media (min-width: 1200px) {  }


/* xxl */
@media (min-width: 1400px) {  }