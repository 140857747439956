:root {
  --dark: #333;
  --light: #f7f8f9;
  --primary: #b76d4f;
  --success: #198754;
  --warning: #ffc107;
  --secondary: #eeeff1;
  --primary-light: #db8e62;
  --success-light: #eafcf6;
  --primary-gradient: linear-gradient(
    -45deg,
    var(--primary-light),
    var(--primary)
  );
  --primary-gradient: linear-gradient(
    -45deg,
    var(--primary),
    var(--primary-light)
  );
  --pagos-gradient: linear-gradient(-45deg, #ff8008, #f6e22b);
  --real-finanzas-gradient: linear-gradient(-45deg, #ff00cc, #cc1b7f);
  --color-gradient: linear-gradient(
    -45deg,
    var(--primary-light),
    var(--primary)
  );
}

/* General */

.pagination .page-link {
  color: var(--primary);
  background-color: white;
  border: 1px solid var(--primary);
}

.pagination .page-link:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

.pagination .active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

a {
  color: var(--primary-light);
}

.hover-primary:hover {
  color: var(--primary);
}

/** Borders **/

.outline-image {
  border: 5px solid #06cb9c !important;
}

.outline-pdf {
  border: 5px solid #fc6767 !important;
}

.outline-ppt {
  border: 5px solid #ffc837 !important;
}

.outline-selected {
  border: 5px solid var(--primary) !important;
}

.border-primary {
  border-color: var(--primary) !important;
}

.outline-accent {
  border: 2px solid var(--primary) !important;
  background-color: transparent;
}

.right-border-accent {
  border-right: 5px solid var(--primary-light);
}

.right-border-gray {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

/** Nav **/

.nav-item.selected {
  color: var(--primary);
  border-right: 4px solid var(--primary);
}

.nav-item:hover {
  color: var(--primary);
}

/** Gradients **/

.real-finanzas-gradiente {
  background-image: var(--real-finanzas-gradient);
}

.pagos-gradiente {
  background-image: var(--pagos-gradient);
}

.programado-gradiente {
  background-image: linear-gradient(-45deg, #007991, #05d4c6);
}

.real-gradiente {
  background-image: linear-gradient(-45deg, #89d405, #b3ff00);
}

.diferencial-gradiente {
  background-image: linear-gradient(-45deg, #dc3545, #fc6767);
}

.ontime-gradiente {
  background-image: linear-gradient(-45deg, #89d405, #b3ff00);
}

.retraso-gradiente {
  background-image: linear-gradient(
    -45deg,
    var(--primary),
    var(--primary-light)
  );
}

/** Charts **/

.circle-progress-fill {
  fill: var(--primary-light);
}

.circle-ontime-fill {
  fill: #89d405;
}

.circle-background {
  stroke: #ddd;
}

.progreso-real {
  stroke: #89d405;
}

.progreso-programado {
  stroke: #05d4c6;
}

.progreso-diferencial {
  stroke: #dc3545;
}

.progreso-naranja {
  stroke: var(--primary-light);
}

/** Checkbox **/

.checkbox {
  border: 1px solid var(--primary) !important;
}

.checkbox.unchecked {
  background-color: white;
}

.checked {
  border: 0.5px solid rgba(0, 0, 0, 0.125);
}

.checkbox.checked,
.checkbox.checked:hover,
.checkbox.checked:disabled {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: white;
  opacity: 1;
}

.checked:not(:disabled):not(.disabled):active {
  border: 0.5px solid rgba(0, 0, 0, 0.125);
  box-shadow: var(--shadow);
}

.checked:not(:disabled):not(.disabled):active:focus {
  border: 0.5px solid rgba(0, 0, 0, 0.125);
  box-shadow: var(--shadow);
}

.unchecked:not(:disabled):not(.disabled) {
  background-color: #fff !important;
}

.unchecked {
  background-color: #fff !important;
}

.unchecked:hover {
  background-color: #fff !important;
  border: 0.5px solid rgba(0, 0, 0, 0.125);
  box-shadow: var(--shadow);
}

.unchecked:disabled {
  background-color: #fff !important;
  border: 0.5px solid rgba(0, 0, 0, 0.125);
}

.unchecked:not(:disabled):not(.disabled):active {
  background-image: var(--primary-gradient);
  border: 0.5px solid rgba(0, 0, 0, 0.125);
}

.unchecked:not(:disabled):not(.disabled):active:focus {
  background-image: var(--primary-gradient);
  border: 0.5px solid rgba(0, 0, 0, 0.125);
}

/** Text **/

.text-primary {
  color: var(--primary) !important;
}

/** Background **/

.bg-real {
  background-color: #b3ff00 !important;
}

.bg-secondary {
  background-color: #eeeff1 !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-primary-flat {
  background-color: var(--primary-light);
}

.bg-primary-light {
  background-color: #fbf8f7;
}

.bg-success-light {
  color: #6ae2a2;
  background-color: var(--success-light) !important;
}

.bg-warning-light {
  background-color: #f9f5ed;
  color: #fec007;
}

.bg-danger-light {
  background-color: #fff2ec;
  color: #f85762;
}

.hover-light:hover {
  background-color: var(--light);
  cursor: pointer;
}

.ion-color-primary,
.ion-color-primary span {
  color: var(--primary) !important;
  --ion-color-base: var(--primary) !important;
  --ion-color-shade: var(--primary) !important;
  --ion-color-tint: var(--primary) !important;
}

:host(.button-clear.ion-color) .button-native {
  color: var(--primary) !important;
  --ion-color-base: var(--primary) !important;
  --ion-color-shade: var(--primary) !important;
  --ion-color-tint: var(--primary) !important;
}

/* Buttons */

.btn-outline-success {
  border-color: var(--success) !important;
}

.btn-outline-warning {
  border-color: var(--warning) !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: inherit;
  color: var(--primary-light);
}

.btn-outline-primary {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-outline-primary:hover,
.btn-outline-primary.focus,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  color: white !important;
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.btn-outline-primary:disabled {
  color: var(--primary-light);
}

.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary.focus,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #fff !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: inherit;
  color: var(--primary-light);
}

.btn-primary.disabled,
.btn-primary:disabled:not(.unchecked) {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: #fff;
  opacity: 0.5;
}

.btn-secondary:hover {
  background-color: transparent;
  border: 2px solid var(--primary) !important;
  color: var(--primary) !important;
}

.btn-secondary:focus {
  box-shadow: var(--shadow);
  background-color: transparent;
  color: var(--primary);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--primary);
  background-color: transparent;
}

.btn-link {
  color: var(--primary);
}

.btn-link:hover {
  color: var(--primary-light);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  box-shadow: var(--primary);
  background-color: var(--primary);
  border-color: var(--primary);
}

/** Tabs **/

.tab-selected {
  border-top: 4px solid var(--primary);
}

/** Misc **/

.list-item:hover {
  background-color: var(--light);
  cursor: pointer;
}

.card.estimaciones-actions {
  border: 1px solid var(--light);
}

#autorotateToggle {
  background-color: var(--primary);
}

#sceneListToggle {
  background-color: var(--primary);
}

.no-touch #sceneList .scene:hover {
  color: var(--primary);
}

.no-touch #sceneList .current .scene:hover {
  background-color: var(--primary);
  color: #fff;
}

#sceneList .scene.current {
  background-color: var(--primary);
  color: #fff !important;
}
