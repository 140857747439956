.proveedores-table-container {
  width: 100%;
  background-color: white;
  padding: 15px;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow-x: auto;
}

.proveedores-table {
  width: 100%;
  min-width: 1300px;
}

.proveedores-table-container table tr {
  vertical-align: middle;
  text-align: center;
}

.proveedor-card .card-title {
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .proveedor-card .card-title {
    max-width: 100%;  
  }
}