.bottom-navbar{
    z-index: 9999 !important;
    background-color: #fff !important;
}

.hidden-tabs {
    bottom: 35px;
    left: 79vw;
    right: 0;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    padding: 50px;
    z-index: 99999;



  }
  
  .overlay {
    position: fixed;
    top: -75px;
    left: 0;
    width: 100%;
    height: 98%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

.hidden-tabs .tab {
  width: 60px !important;
  height: 60px !important;
  margin: 5px 0;
  border-radius: 10%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #333;
  background-color: #f8f9fa;
  position: relative;
  bottom: auto;
}

.hidden-tabs .tab:hover {
  background-color: #17a2b8;
  color: #000;
}

.hidden-tabs .tab-selected {
  background-color: #fff;
  color: #000;
}

.hidden-tabs.show {
  bottom: 0;
  top: auto;
}


/* animacion */

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation: fadeInUp 0.5s ease-out;
}
