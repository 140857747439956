:root {
    --light: #f8f9fa
}

.form-control,
.form-control:focus,
.form-control:hover,
.form-control:focus-within,
.form-control:active {
    background-color: var(--light);
    box-shadow: none;
    border: 0px;
}