.button {
    border-radius: 5px;
}

.btn-outline-primary .btn-group-lg>.btn,
.btn-lg {
    padding: 12px 24px;
    min-width: 200px;
}

.btn {
    font-weight: 600;
    border-width: 1px;
    border-radius: 5px;
    transition: all 0.5s;
    text-transform: uppercase;
}

.btn-outline-success, .btn-outline-warning {  
    border-width: 1px;
    border-style: solid;
}

.btn-sm {
    font-weight: 400;
    border-radius: 5px;
}

.btn-options {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 200;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-text {
    padding-left: 15px;
}
