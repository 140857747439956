.search-bar {
  min-width: 50%;
  height: 100%;
}

.search-bar input {
  border-radius: 10px;
  border: none;
  outline: none;
}

.search-bar input:focus {
  border: none;
  outline: none;
}
