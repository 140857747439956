@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
    .item-native {
        padding-left: 5px !important;
    }
}

.sc-ion-label-ios-s p.small {
    font-size: 12px;
}

.ionic h3 {
    font-size: 1rem !important;
}

.ionic .btn {
    font-size: 0.8rem !important;
}

.ionic .chart-wrapper {
    margin-left: -1.5rem !important;
    margin-top: -1.2rem !important;
}

.ionic .badge {
    font-size: 0.8rem !important;
}

.ionic .obra-card {
    padding: 24px 16px !important;
}