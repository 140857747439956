.product-table {
  min-width: 1300px;
}

.product-table-container table tr {
  vertical-align: middle;
}

.product-article {
  text-align: start;
  position: relative;
}

.product-article p {
  margin: 0 0 0 45px;
}

.expand-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: white;
  top: 18px;
  padding: 4px 6px;
  border: none;
  border-radius: 5px;
}

.product-table-buttons {
  min-width: 200px;
  display: flex;
  justify-content: flex-end;
}

.product-table-buttons button {
  margin: 0 5px;
  padding: 5px;
}
