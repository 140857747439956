.bold {
    font-weight: bold;
  }
  
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
  
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
  
  .mw-100 {
    max-width: 100%;
  }

  .btn.no-border {
    --border-style: none;
  }