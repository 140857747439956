.file-preview {
  width: 45%;
}

@media (min-width: 500px) {
  .file-preview {
    width: 28%;
  }
}

@media (min-width: 992px) {
  .file-preview {
    width: 23%;
  }
}

