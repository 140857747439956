

.modal-header {
    background-color: #fff;
}

.modal-content {
    height: max-content;
    max-height: calc(100vh - 56px);
    background-color: transparent;
    border: none;
    position: relative;
}

.modal-body {
    background-color: white;
    height: 100%;
    max-height: 100%;
}