@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);


.modal-header {
    background-color: #fff;
}

.modal-content {
    height: -webkit-max-content;
    height: max-content;
    max-height: calc(100vh - 56px);
    background-color: transparent;
    border: none;
    position: relative;
}

.modal-body {
    background-color: white;
    height: 100%;
    max-height: 100%;
}
:root {
  --shadow-original: 0 0 0 0.2rem rgba(252, 103, 103, 0.4);
  --shadow: 0 0 0 0.2rem rgba(189, 109, 79, 0.4);
  -webkit-user-select: text !important;
          user-select: text !important;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
div,
li,
p,
a,
ion-label {
  font-family: "Poppins", sans-serif;
}

h1 {
  margin-bottom: 0px;
}

body {
  background-color: var(--light);
}

body.backdrop-no-scroll {
  width: 100vw;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

/* styles.css */

.icon-wrapper {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper-lg {
  width: 125px;
  height: 125px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
}

.custom-ionitem::part(native) {
  background-color: #fcf8f7;
}

.custom-icon {
  object-fit: contain;
  display: inline-block;
  max-width: 25px;
  width: 25px;
  height: 25px;
}

.franja {
  background-color: #eeeff1;
  height: 100px;
  margin-left: -2vw;
  margin-top: -1.2vw;
  width: 104.5%;
}

.folder-card img {
  z-index: 5;
}

.folder-options {
  position: absolute;
  top: 20px;
  right: 35px;
  z-index: 200;
}

.folder-btn {
  top: 35px;
  right: 15px;
  z-index: 200;
  position: absolute;
}

.folder-title-container {
  align-items: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  top: 0px;
}

.folder-title {
  font-size: 1.75rem;
  text-align: center;
  z-index: 10;
}

.border-0 {
  border: none !important;
}

.border-0 {
  border: none !important;
}

/* adjunto card */

.btn-options-container {
  transform: rotate(90deg) !important;
}

.btn-options-container i.fa {
  margin-left: 0px;
}

.cardOption {
  margin-top: -170px;
  margin-left: 10px;
}

.card {
  border: 0px;
  border-radius: 10px !important;
}

.adjunto-card,
.adjunto-card * {
  border-radius: 5px;
}

.adjunto-options {
  top: 5px;
  right: 30px;
  z-index: 200;
  position: absolute;
  border-radius: 10px;
}

.adjunto-card .btn-options {
  position: absolute;
  z-index: 15;
  right: 0px;
}


.adjunto-card-footer {
  border-radius: 0px 0px 5px 5px;
  background-color: white;
  padding-bottom: 15px;
  position: absolute;
  padding-top: 15px;
  max-width: 100%;
  width: 100%;
  z-index: 10;
  bottom: 0px;
}

.adjunto-card-title {
  width: 100%;
  height: 45px;
  text-overflow: ellipsis;
}

.adjunto-card-img {
  z-index: 1;
  width: 100%;
  height: 260px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.small .user-item__name {
  font-size: 12px;
}

.user-item__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.react-pdf__Document {
  overflow: scroll;
  height: 100%;
}

.view-container {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .view-container {
    height: calc(100vh - 100px);
  }
}

#adjunto-view,
#adjunto-view * {
  z-index: 1100;
}

#adjunto-view .modal-content {
  background-color: white;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.text-overflow {
  max-width: 100%;
  max-height: 75px;
  text-overflow: ellipsis;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  -webkit-user-select: text;
  user-select: text;
}

input[type="file"]:not(.form-control) {
  padding: 40px 0 0 0;
  height: 10px;
  width: 50px;
  overflow: hidden;
  box-sizing: border-box;
}

html {
  font-family: "Montserrat", sans-serif !important;
}

/* Waves */

.wave {
  position: absolute;
  bottom: -10;
  width: 300%;
  height: 20vh;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {

  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }
}

/* Tags */
.custom-tooltip {
  --bs-tooltip-width: 250px;
}

a:not(.btn) {
  width: 100%;
  display: block;
  margin: auto;
}

a:hover:not(.text-decoration) {
  color: inherit;
  text-decoration: none;
}

a:focus {
  color: inherit;
  background-color: inherit;
}

tbody,
td,
tr {
  border: 0px !important;
}

td {
  padding-top: 12px;
  align-items: center;
  padding-bottom: 12px;
}

/* General */

.show-tablet {
  display: none;
}

.show-mobile-flex {
  display: none;
}

.show-mobile {
  display: none;
}

.obra-details {
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
}

.obra-details h5 {
  font-size: 1rem;
}

.nav-container {
  padding-top: 1.5rem;
}

.nav-item {
  transition: all 0.5s;
  padding: 0.15rem 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
}

.nav-item.selected {
  font-weight: bold;
}

td.rubro {
  min-width: 500px;
  max-width: 500px;
}

td.ponderacion {
  text-align: right;
  max-width: 60px;
  min-width: 60px;
}

td.destajos {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1rem;
}

td.buttons {
  min-width: 150px;
}

.sidebar {
  top: 0;
  left: 0;
  z-index: 20;
  width: 200px;
  height: 100%;
  overflow: auto;
  position: fixed;
  padding-top: 6px;
  overflow-x: hidden;
  background-color: #fff;
  transition: all 0.3s ease;
}


.sidebar.closed {
  width: 100px;
}

.profile-image {
  width: 50px;
  height: 50px;
  margin-bottom: 26px;
  border-radius: 50%;
  object-fit: contain !important;
}

.profile-image.large {
  width: 80px;
  height: 80px;
  margin-left: -30px;
  margin-bottom: 16px;
}

.toggle-menu-container {
  width: 70%;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: -20px;
  background-color: #ffffff;
  padding: 10px 15px;
  cursor: pointer;
}

.sidebar.closed .toggle-menu-container {
  height: 48px;
}

.sidebar.closed .toggle-menu {
  display: inline;
  position: absolute;
  right: -10px;
  justify-content: center;
  margin-left: -30px;
}

.menu-tab {
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
}

.menu-tab-open {
  width: 150px;
  height: 50px;
  margin: auto;
  display: block;
}

.menu-content {
  align-items: center;
  margin-top: 16px;
  width: 100%;
}

.toggle-menu-text {
  margin: 0;
}

.sidebar.open .toggle-menu {
  margin-left: auto;
}

.sidebar.open .menu-item {
  display: flex;
  align-items: center;
  margin-top: 0px !important;
}

.sidebar.closed .menu-item {
  display: flex;
  align-items: center;
  margin-top: 0px !important;
}

.menu-icon svg {
  width: 25px;
  height: 25px;
}

.menu-item:hover {
  border-radius: 10px;
  background-color: #db8c63 !important;
  color: white;
}

.menu-item:hover .menu-icon {
  color: white;
}

.menu-item:hover .menu-icon svg {
  fill: white;
}

.menu-item.active {
  border-radius: 10px;
  background-color: #db8c63 !important;
  color: white;
}

.menu-item.active .menu-icon {
  color: white;
}

.menu-item.active .menu-icon svg {
  fill: white;
}

.info-card .card-title {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 4px;
}


.title {
  display: none;
}

/* PLANTILLA */
.fondoIndividualesPlantilla {
  background-color: #f8f9fa;
  border-radius: 8px;
}

.titlePlantilla {
  font-weight: 600;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
}

.titleInputPlantilla {
  letter-spacing: .5px;
  font-weight: 600;
  color: #c5c5c5;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.titleCuadradoPlantilla {
  letter-spacing: .5px;
  color: #b86d4f;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.fondoIndividualesPlantilla {
  color: #ababab;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  height: 160px;
}

/* usuarios */
.table-usuarios {
  border-style: hidden !important;
}

/* pagination */
.pagination li.rounded {
  border-radius: 10px !important;
}

.pagination li:not(:last-child) {
  margin-right: 5px;
}

.pagination li:first-child,
.pagination li:last-child {
  display: none;
}

.pagination .page-link {
  width: 40px;
  font-weight: 600;
}

#navbar {
  z-index: 20;
  margin-left: 100px;
  width: calc(100vw - 100px);
  box-shadow: .5rem .5rem 1rem -.2rem rgba(var(--bs-body-color-rgb), .15) !important;
  transition: all 0.3s ease;
}


#content {
  overflow: hidden;
  z-index: 5;
  width: 100vw;
  min-height: 100vh;
  padding-top: 65px;
  margin-left: 100px;
  padding-bottom: 2rem;
  width: calc(100vw - 100px);
}

@media (min-width: 768px) {
  #content {
    width: calc(100vw - 100px);
    padding-bottom: 0 !important;
  }
}

@media (max-width: 1200px) {
  .chart-wrapper {
    margin-bottom: -1.5rem;
  }

  td.destajos {
    padding-left: 0px;
  }

  h1 {
    font-size: 2rem;
  }

  .folder-title {
    font-size: 1.25rem;
  }

  .cronograma-desktop .h4 {
    font-size: 1.25rem;
  }

  .cronograma-desktop .h5 {
    font-size: 1rem;
  }

  .obra-details {
    margin-top: 1.5rem;
  }

  .nav-container {
    padding-top: 0px;
  }

  .item-overflow-ellipsis {
    overflow: hidden;
    max-height: 60px;
    text-overflow: ellipsis;
  }

  img.img-obra {
    width: 75px;
    height: 75px;
    margin: auto;
    display: block;
    object-fit: cover;
  }

  .hide-tablet {
    display: none;
  }

  .show-tablet {
    display: inherit;
  }
}

/* Elements */

.react-pdf__Page__canvas {
  margin: auto;
  width: 100%;
}

.bitacoraImage {
  object-fit: cover;
  max-width: 35px;
  max-height: 35px;
  height: 35px;
}

.bitacora-container {
  bottom: 0 !important;
  position: "fixed" !important;
  max-width: "100%";
  width: "100%";
}

.bitacora-container * {
  max-height: 50px;
}


.bitacora-input:focus {
  box-shadow: none !important;
}

.progreso-container {
  margin-top: -50px;
}


.folder-caption {
  position: relative;
  top: -140px;
  width: 100%;
}

.text-link:hover {
  cursor: pointer;
}

.folder-row:hover {
  cursor: pointer;
}


.Cal__Weekdays__day {
  color: #6c757d;
}

.button {
  transition: 0.5s;
}

.button:hover {
  transform: scale(1.05);
}

.outline-accent {
  padding: 15px 35px;
}

.tour-card {
  transition: all 0.5s;
}

.tour-card:hover {
  cursor: pointer;
}

@media (min-width: 750px) {
  #bitacora-input {
    width: 98%;
    bottom: 15px !important;
  }

}


@media (max-width: 576px) {
  .progreso-container {
    margin-top: 0px;
  }

  div.chart-wrapper {
    margin-left: -2.5rem !important;
  }

  .btn-text {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center !important;
  }

  .login {
    min-height: 100vh;
  }

  .login-title {
    padding: 0px !important;
  }
}

@media (max-width: 576px) {
  div.chart-wrapper {
    margin-top: 0.5rem !important;
  }
}

.colorBox {
  padding: 0.5px 12px;
  border-radius: 5px;
  margin-right: 5px;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-progress-fill {
  fill-opacity: 0.35;
}

.circle-ontime-fill {
  fill-opacity: 0.35;
}

.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}

/* CheckBox */

.checkbox {
  width: 25px;
  height: 25px;
}

.checked:disabled {
  opacity: 1;
}

.checked:not(:disabled):not(.disabled):active {
  box-shadow: var(--shadow);
}

.checked:not(:disabled):not(.disabled):active:focus {
  box-shadow: var(--shadow);
}

.unchecked:hover {
  box-shadow: var(--shadow);
}

.unchecked:disabled {
  opacity: 1;
}

.unchecked:disabled:hover {
  box-shadow: none;
}

.unchecked:not(:disabled):not(.disabled):active {
  box-shadow: var(--shadow);
}

.unchecked:not(:disabled):not(.disabled):active:focus {
  box-shadow: var(--shadow);
}

/* Attributes */
.text-underline {
  text-decoration: underline !important;
}

.shadow-md {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.br-10 {
  border-radius: 10px;
}

.round5 {
  border-radius: 5px;
}

.round10 {
  border-radius: 10px;
}

.round15 {
  border-radius: 15px;
}

.round20 {
  border-radius: 20px;
}

.round25 {
  border-radius: 25px;
}

.br-20 {
  border-radius: 20px;
}

.bold {
  font-weight: 800;
}

ul.list-style-none {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bottomRound {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

/* Layout */
.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.mx-n-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.pr-0 {
  padding-right: 0px;
}

.ps-0 {
  padding-left: 0px;
}

.mt-center {
  margin-top: 15vh;
}

.pt-center {
  padding-top: 18vh;
}

.pt-30 {
  padding-top: 25vh;
}

.img-obra {
  height: 150px;
  max-width: 150px;
  object-fit: contain;
  border-radius: 300px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .2);
}

.makeStyles-appBar-1 {
  box-shadow: none !important;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-menu {
  position: absolute;
  width: 100%;
  right: 5px;
  top: 60px;
}

.offcanvas {
  z-index: 15 !important;
  transition: all 0.5s;
}

.offcanvas * {
  transition: all 0.5s;
}

.offcanvas-header {
  margin-top: 80px;
}

.max-h-auto {
  max-height: unset !important;
}

.list-style-type-none {
  list-style-type: none;
  margin-bottom: 0px;
  padding-left: 0px;
}

.list-style-type-none li {
  text-align: left;
}

.list-item {
  padding: 6px 12px;
  font-size: 14px;
}

.list-item i {
  font-size: 12px;
  margin-right: 10px;
}

.estimaciones-actions {
  width: 215px;
  right: 15px;
  top: 40px;
}

.menu-card {
  height: 35vh; 
}

.concepto-generador-actions {
  position: absolute;
  width: 100px;  
  top: -1px;
}

@media (max-width: 767px) {
  .permisos-container {
    padding: 0px !important;
  }

  .btn-tab {
    font-size: 12px !important;
    text-transform: uppercase;
  }

  .chart-wrapper {
    margin-left: -1rem !important;
    margin-top: -1rem !important;
  }

  .sidebar {
    display: none !important;
  }

  .navbar {
    display: none;
  }

  .title {
    display: flex;
  }

  #content {
    width: 100vw;
    /* margin-top: ; */
    margin-left: 0px;
    padding-top: 70px;
  }

  .avance-picker {
    max-width: 100px;
  }

  .files-name {
    margin-top: 36px;
  }

  .form-control {
    font-size: 0.8rem;
  }

  .checkbox {
    font-size: 8px;
    width: 15px;
    height: 15px;
  }

  .adjunto-card-footer {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .adjunto-card-img {
    height: 200px;
  }

  .folder-btn {
    top: 15px;
    right: 0px;
    position: absolute;
  }

  .folder-title {
    font-size: 1rem;
  }

  .modal.modal-lg {
    left: 0px;
  }

  .mt-center {
    margin-top: 10vh;
  }

  .pt-center {
    padding-top: 5vh;
  }

  .hide-mobile {
    display: none;
  }

  .show-mobile-flex {
    display: flex;
  }

  .show-mobile {
    display: block;
  }

  .bottom-navbar {
    display: block !important;
  }

  div,
  p,
  span,
  li,
  b {
    font-size: 14px;
  }

  .mobile-left {
    text-align: left !important;
  }

  .mobile-center {
    text-align: center !important;
  }

  .tab {
    transition: all 0.5s;
    border-top: 4px solid transparent;
  }

  .bitacoraImage {
    object-position: 100% 0;
  }

  .bitacora-container {
    width: "95%";
  }

  .outline-accent {
    padding: 15px;
    margin-bottom: 15px;
  }

  #sidebar {
    display: none;
  }
}

/* Colors */

.toggle {
  cursor: pointer;
}

.hover-primary:hover {
  cursor: pointer;
}

.tabla-preciario td.nombre-concepto {
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 300px !important;
  max-width: 300px !important;
}

.partida-preciario:not(.extra) {
  background-color: #c8f0e6;
  border-bottom: 1px solid #ced4da !important;
  cursor: pointer;
}

.concepto-aditivo {
  background-color: #fffaea;
}

.concepto-extra {
  background-color: rgb(255, 239, 239);
}

.extra {
  background-color: rgb(255, 239, 239);
}

.border-card {
  border: 0.5px solid #f3f3f3;
}

.min-100 {
  min-width: 100px;
}

.min-120 {
  min-width: 120px;
}


.concepto-row td {
  padding-right: 0px;
}

ul.unstyled {
  padding-left: 0px;
  list-style-type: none;
}

.nav-tabs .nav-link {
  max-width: 50px !important;
  margin-left: 0px;
  margin-right: 5px;
}



.bottom-navbar {
  z-index: 1 !important;
  position: fixed;
  display: none;
  /* z-index: 1032; */
  bottom: 0px;
  width: 100%;
}

.bottom-navbar i {
  font-size: 24px;
}

td {
  vertical-align: middle;
  padding-left: 6px;
  padding-right: 6px;
}

.table>:not(caption)>*>* {
  border-bottom: 0px;
}

.th-border-0 th {
  border: 0px;
}

.tabla-preciario td,
.tabla-preciario th {
  min-width: 100px;

}

.tabla-preciario th {
  font-size: 14px;
}

.td-short {
  max-width: 50px;
}

.mw-50-px {
  max-width: 50px;
  width: 50px;
}

.user-card {
  max-width: 200px;
  max-height: 100px;
}

.user-card img {
  object-fit: contain;
  max-height: 35px;
}

div#entradas {
  height: 75vh;
  overflow-y: scroll;
}

input#fileInput {
  position: absolute;
}

.hidden {
  display: none;
}

.alert {
  z-index: 1000;
}

.login-container {
  margin: auto;
  display: block;
  max-width: 600px;
}

.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-login {
  background-image: url(/static/media/fondo_login.e1958ebf.png);
}

.fw-400 {
  font-weight: 400;
}

.color {
  height: 35px;
  width: 35px;
  border-radius: 70px;
  display: block;
}

.thumbnail {
  max-width: 50px;
  object-fit: contain;
}

.thumbnail.sm {
  max-width: 35px;
}

.btn-tab {
  font-size: 14px;
  font-weight: 400;
  min-width: 100px;
  text-transform: uppercase;
}

.chart-wrapper {
  margin-left: -2rem;
  margin-top: -1rem;
  margin-bottom: -1.5rem;
  width: calc(100% + 3rem) !important;
}

.activity {
  height: 80px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge {
  font-weight: 600;
  font-size: 0.85rem !important;
}

.progress-chart {
  z-index: 1;
}

.progress,
.progress-bar {
  display: block !important;
}

.options-container {
  position: absolute;
  top: 10px;
  right: 40px;
  z-index: 200;
}

#login-logo {
  max-width: 150px;
}

.picker {
  min-width: 150px;
  max-width: 150px;
  width: 150px;
}

.avance-picker {
  width: 100%;
  margin-left: 0px;
  max-width: 200px;
  border-radius: 10px;
}

.br-0 {
  border-radius: 0px;
}

.br-10 {
  border-radius: 10px;
}

.avance-picker .button-left {
  border-radius: 6px 0px 0px 6px !important;
}

.avance-picker .button-right {
  border-radius: 0px 6px 6px 0px !important;
}

.transition,
.transition * {
  transition: all 0.5s;
}

.cronograma-mobile {
  height: calc(100% - 75px);
}

.estimacion-resumen tr, .estimacion-resumen td {
  border: 1px solid rgba(0,0,0,0.1) !important;
}

.badge.small {
  font-size: 12px !important;
}

.table.border td.border-0 {
  border: 0px !important;
}

.preciario td, table.border td:not(.border-0), table.border th {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.firma-mobile svg {
  height: 100px;
}

#preciario-mobile {
  margin-left: -15px;
}

.adjunto-mensaje {
  max-height: 250px;
  object-fit: contain;
}

@media (max-width: 767px) {
  .adjunto-mensaje {
    max-height: 200px;
  }
}

@media print {
  body{
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .sidebar {
    display: none !important;
  }
  #content {
    width: 100vw;
    margin-left: 0px !important;
  }
  #report-header {
    display: none;
  }
  #entradas.h-100 {
    height: auto !important;
    overflow-y: visible !important;
  }
  #bitacora-input {
    display: none;
  }
  #obra-view.h-100 {
    height: auto !important;
    overflow-y: visible !important;
  }
  .h-100 {
    height: auto !important;
  }
  .navbar-toggler {
    display: none;
  }
  .fa-chevron-left {
    display: none;
  }
  .adjunto-mensaje {
    max-height: 100px;
    object-fit: contain;
  }
  .msg-card.text-white {
    color: #000 !important;
  }
  .msg-card {
    padding: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
  }
  .navbar {
    display: none !important;
  }
  .navbar .col-8 {
    height: auto !important;
  }
  .shadow {
    box-shadow: none;
  }
  #obra-view, #obra-view .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
  .cronograma-mobile .item-inner {
    padding-right: 0px !important;
  }

}
:root {
  --dark: #333;
  --light: #f7f8f9;
  --primary: #b76d4f;
  --success: #198754;
  --warning: #ffc107;
  --secondary: #eeeff1;
  --primary-light: #db8e62;
  --success-light: #eafcf6;
  --primary-gradient: linear-gradient(
    -45deg,
    var(--primary-light),
    var(--primary)
  );
  --primary-gradient: linear-gradient(
    -45deg,
    var(--primary),
    var(--primary-light)
  );
  --pagos-gradient: linear-gradient(-45deg, #ff8008, #f6e22b);
  --real-finanzas-gradient: linear-gradient(-45deg, #ff00cc, #cc1b7f);
  --color-gradient: linear-gradient(
    -45deg,
    var(--primary-light),
    var(--primary)
  );
}

/* General */

.pagination .page-link {
  color: var(--primary);
  background-color: white;
  border: 1px solid var(--primary);
}

.pagination .page-link:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

.pagination .active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

a {
  color: var(--primary-light);
}

.hover-primary:hover {
  color: var(--primary);
}

/** Borders **/

.outline-image {
  border: 5px solid #06cb9c !important;
}

.outline-pdf {
  border: 5px solid #fc6767 !important;
}

.outline-ppt {
  border: 5px solid #ffc837 !important;
}

.outline-selected {
  border: 5px solid var(--primary) !important;
}

.border-primary {
  border-color: var(--primary) !important;
}

.outline-accent {
  border: 2px solid var(--primary) !important;
  background-color: transparent;
}

.right-border-accent {
  border-right: 5px solid var(--primary-light);
}

.right-border-gray {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

/** Nav **/

.nav-item.selected {
  color: var(--primary);
  border-right: 4px solid var(--primary);
}

.nav-item:hover {
  color: var(--primary);
}

/** Gradients **/

.real-finanzas-gradiente {
  background-image: var(--real-finanzas-gradient);
}

.pagos-gradiente {
  background-image: var(--pagos-gradient);
}

.programado-gradiente {
  background-image: linear-gradient(-45deg, #007991, #05d4c6);
}

.real-gradiente {
  background-image: linear-gradient(-45deg, #89d405, #b3ff00);
}

.diferencial-gradiente {
  background-image: linear-gradient(-45deg, #dc3545, #fc6767);
}

.ontime-gradiente {
  background-image: linear-gradient(-45deg, #89d405, #b3ff00);
}

.retraso-gradiente {
  background-image: linear-gradient(
    -45deg,
    var(--primary),
    var(--primary-light)
  );
}

/** Charts **/

.circle-progress-fill {
  fill: var(--primary-light);
}

.circle-ontime-fill {
  fill: #89d405;
}

.circle-background {
  stroke: #ddd;
}

.progreso-real {
  stroke: #89d405;
}

.progreso-programado {
  stroke: #05d4c6;
}

.progreso-diferencial {
  stroke: #dc3545;
}

.progreso-naranja {
  stroke: var(--primary-light);
}

/** Checkbox **/

.checkbox {
  border: 1px solid var(--primary) !important;
}

.checkbox.unchecked {
  background-color: white;
}

.checked {
  border: 0.5px solid rgba(0, 0, 0, 0.125);
}

.checkbox.checked,
.checkbox.checked:hover,
.checkbox.checked:disabled {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: white;
  opacity: 1;
}

.checked:not(:disabled):not(.disabled):active {
  border: 0.5px solid rgba(0, 0, 0, 0.125);
  box-shadow: var(--shadow);
}

.checked:not(:disabled):not(.disabled):active:focus {
  border: 0.5px solid rgba(0, 0, 0, 0.125);
  box-shadow: var(--shadow);
}

.unchecked:not(:disabled):not(.disabled) {
  background-color: #fff !important;
}

.unchecked {
  background-color: #fff !important;
}

.unchecked:hover {
  background-color: #fff !important;
  border: 0.5px solid rgba(0, 0, 0, 0.125);
  box-shadow: var(--shadow);
}

.unchecked:disabled {
  background-color: #fff !important;
  border: 0.5px solid rgba(0, 0, 0, 0.125);
}

.unchecked:not(:disabled):not(.disabled):active {
  background-image: var(--primary-gradient);
  border: 0.5px solid rgba(0, 0, 0, 0.125);
}

.unchecked:not(:disabled):not(.disabled):active:focus {
  background-image: var(--primary-gradient);
  border: 0.5px solid rgba(0, 0, 0, 0.125);
}

/** Text **/

.text-primary {
  color: var(--primary) !important;
}

/** Background **/

.bg-real {
  background-color: #b3ff00 !important;
}

.bg-secondary {
  background-color: #eeeff1 !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-primary-flat {
  background-color: var(--primary-light);
}

.bg-primary-light {
  background-color: #fbf8f7;
}

.bg-success-light {
  color: #6ae2a2;
  background-color: var(--success-light) !important;
}

.bg-warning-light {
  background-color: #f9f5ed;
  color: #fec007;
}

.bg-danger-light {
  background-color: #fff2ec;
  color: #f85762;
}

.hover-light:hover {
  background-color: var(--light);
  cursor: pointer;
}

.ion-color-primary,
.ion-color-primary span {
  color: var(--primary) !important;
  --ion-color-base: var(--primary) !important;
  --ion-color-shade: var(--primary) !important;
  --ion-color-tint: var(--primary) !important;
}

:host(.button-clear.ion-color) .button-native {
  color: var(--primary) !important;
  --ion-color-base: var(--primary) !important;
  --ion-color-shade: var(--primary) !important;
  --ion-color-tint: var(--primary) !important;
}

/* Buttons */

.btn-outline-success {
  border-color: var(--success) !important;
}

.btn-outline-warning {
  border-color: var(--warning) !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: inherit;
  color: var(--primary-light);
}

.btn-outline-primary {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.btn-outline-primary:hover,
.btn-outline-primary.focus,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  color: white !important;
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}

.btn-outline-primary:disabled {
  color: var(--primary-light);
}

.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary.focus,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #fff !important;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: inherit;
  color: var(--primary-light);
}

.btn-primary.disabled,
.btn-primary:disabled:not(.unchecked) {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: #fff;
  opacity: 0.5;
}

.btn-secondary:hover {
  background-color: transparent;
  border: 2px solid var(--primary) !important;
  color: var(--primary) !important;
}

.btn-secondary:focus {
  box-shadow: var(--shadow);
  background-color: transparent;
  color: var(--primary);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--primary);
  background-color: transparent;
}

.btn-link {
  color: var(--primary);
}

.btn-link:hover {
  color: var(--primary-light);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  box-shadow: var(--primary);
  background-color: var(--primary);
  border-color: var(--primary);
}

/** Tabs **/

.tab-selected {
  border-top: 4px solid var(--primary);
}

/** Misc **/

.list-item:hover {
  background-color: var(--light);
  cursor: pointer;
}

.card.estimaciones-actions {
  border: 1px solid var(--light);
}

#autorotateToggle {
  background-color: var(--primary);
}

#sceneListToggle {
  background-color: var(--primary);
}

.no-touch #sceneList .scene:hover {
  color: var(--primary);
}

.no-touch #sceneList .current .scene:hover {
  background-color: var(--primary);
  color: #fff;
}

#sceneList .scene.current {
  background-color: var(--primary);
  color: #fff !important;
}

.button {
    border-radius: 5px;
}

.btn-outline-primary .btn-group-lg>.btn,
.btn-lg {
    padding: 12px 24px;
    min-width: 200px;
}

.btn {
    font-weight: 600;
    border-width: 1px;
    border-radius: 5px;
    transition: all 0.5s;
    text-transform: uppercase;
}

.btn-outline-success, .btn-outline-warning {  
    border-width: 1px;
    border-style: solid;
}

.btn-sm {
    font-weight: 400;
    border-radius: 5px;
}

.btn-options {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 200;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-text {
    padding-left: 15px;
}

@supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
    .item-native {
        padding-left: 5px !important;
    }
}

.sc-ion-label-ios-s p.small {
    font-size: 12px;
}

.ionic h3 {
    font-size: 1rem !important;
}

.ionic .btn {
    font-size: 0.8rem !important;
}

.ionic .chart-wrapper {
    margin-left: -1.5rem !important;
    margin-top: -1.2rem !important;
}

.ionic .badge {
    font-size: 0.8rem !important;
}

.ionic .obra-card {
    padding: 24px 16px !important;
}
:root {
    --light: #f8f9fa
}

.form-control,
.form-control:focus,
.form-control:hover,
.form-control:focus-within,
.form-control:active {
    background-color: var(--light);
    box-shadow: none;
    border: 0px;
}
.product-table {
  min-width: 1300px;
}

.product-table-container table tr {
  vertical-align: middle;
}

.product-article {
  text-align: start;
  position: relative;
}

.product-article p {
  margin: 0 0 0 45px;
}

.expand-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: white;
  top: 18px;
  padding: 4px 6px;
  border: none;
  border-radius: 5px;
}

.product-table-buttons {
  min-width: 200px;
  display: flex;
  justify-content: flex-end;
}

.product-table-buttons button {
  margin: 0 5px;
  padding: 5px;
}

.search-bar {
  min-width: 50%;
  height: 100%;
}

.search-bar input {
  border-radius: 10px;
  border: none;
  outline: none;
}

.search-bar input:focus {
  border: none;
  outline: none;
}

.product-table-plus-button {
  padding: 8px 15px;
  background-color: #db8d62;
}

/* styles.css */

  .inputWithIcon {
    position: relative;
    margin-top: -100px;
    margin-left: 30px;
    width: 84%;
  }
  
  .inputWithIcon input {
    height: 50px;
    border: 3px solid #db8c63;
    box-sizing: border-box;
    border-radius: 10px;
  }
  
  .inputWithIcon input::placeholder {
    color: gray;
  }
  
  .inputWithIcon .sendButton {
    position: absolute;
    right: 8px;
    top: 0;
    margin-top: 7px;
    border-radius: 10px;
    height: 36px;
    background-color: #db8c63;
    border: none;
    color: white;
    font-size: 1rem;
    padding: 0 10px;
    cursor: pointer;
  }
  
  .inputWrapper {
    display: flex;
    align-items: center;
  }
  
  .inputField {
    height: 50px;
    border-radius: 5px 0 0 5px;
    border-right: none;
  }
  
  .sendButton {
    height: 50px;
    border-radius: 0 5px 5px 0;
  }
  

.calendarContainer {
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: #f8f9fa;
  }


.bold {
    font-weight: bold;
  }
  
  .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
  
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
  
  .mw-100 {
    max-width: 100%;
  }

  .btn.no-border {
    --border-style: none;
  }
.file-preview {
  width: 45%;
}

@media (min-width: 500px) {
  .file-preview {
    width: 28%;
  }
}

@media (min-width: 992px) {
  .file-preview {
    width: 23%;
  }
}



* {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -ms-content-zooming: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a:not(.text-underline), a:hover:not(.text-underline), a:active:not(.text-underline), a:visited:not(.text-underline) {
  text-decoration: none;
  color: inherit;
}

#pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mobile #pano {
  top: 50px;
}

.scene-list {
  padding-left: 0px;
  list-style-type: none;
}

span.scene {
  padding-left: 16px;
  padding-right: 16px;
}

#titleBar {
  position: absolute;
  bottom: 10px;  
  height: 48px;
  border-radius: 100px;
  text-align: center;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
}

.mobile #titleBar {
  display: block;
  margin: auto;  
  max-width: 92%;
  height: 50px;
  left: 4%;  
}


#titleBar .sceneName {
  width: 100%;
  height: 100%;
  line-height: 30px;
  padding: 5px;
  overflow: hidden;  
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: text;
  user-select: text;
  font-size: 1.2rem;
}

.mobile #titleBar .sceneName {
  line-height: 40px;
  margin-top: 0px !important;
}

#fullscreenToggle {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: rgb(103,115,131);
  background-color: rgba(103,115,131,0.8);
}

.mobile #fullscreenToggle {
  width: 50px;
  height: 50px;
}

.fullscreen-enabled #fullscreenToggle {
  display: block;
}

#fullscreenToggle .icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
}

.mobile #fullscreenToggle .icon {
  top: 10px;
  right: 10px;
}

#fullscreenToggle .icon.on {
  display: none;
}

#fullscreenToggle .icon.off {
  display: block;
}

#fullscreenToggle.enabled .icon.on {
  display: block;
}

#fullscreenToggle.enabled .icon.off {
  display: none;
}

#autorotateToggle {
  display: block;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 80px;
}

#autorotateToggle .icon {
  width: 30px;
  height: 30px;
}

#autorotateToggle .icon.on {
  display: none;
}

#autorotateToggle .icon.off {
  display: block;
}

#autorotateToggle.enabled .icon.on {
  display: block;
}

#autorotateToggle.enabled .icon.off {
  display: none;
}

#sceneListToggle {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 80px;
}

#sceneListToggle .text {
  position: absolute;
  top: 5px;
  left: 15px;
  width: 100%;
  line-height: 30px;
}

#sceneListToggle .icon {
  width: 30px;
  height: 30px;
}

#sceneListToggle .icon.on {
  display: none;
}

#sceneListToggle .icon.off {
  display: block;
}

#sceneListToggle.enabled .icon.on {
  display: block;
}

#sceneListToggle.enabled .icon.off {
  display: none;
}

#sceneList {
  position: absolute;
  bottom: 10px;
  right: -300px;
  max-width: 300px;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 0;
  transition: margin-right 0.2s ease-in-out;
}

#sceneList .scenes {
  width: 100%;
}

.mobile #sceneList {
  max-width: 92%;
  width: 100%;
  right: -106%;
  bottom: 80px;
}

.mobile #sceneList.enabled {
  margin-right: 110%;
}

.mobile #sceneList .scenes {
  height: 100%;
}

#sceneList.enabled {
  margin-right: 310px;
}

#sceneList .scene {
  display: block;
  width: 100%;
  height: 30px;
}

.mobile #sceneList .scene {
  height: 40px;
}

#sceneList .scene .text {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile #sceneList .scene .text {
  line-height: 40px;
}

.no-touch #sceneList .scene:hover {
  cursor: pointer;  
}

/* Hide scene list when only a single scene exists */
.single-scene #sceneList, .single-scene #sceneListToggle {
  display: none;
}

/* Link hotspot */

.link-hotspot {
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  opacity: 0.9;
  transition: opacity 0.2s;
}

.no-touch .link-hotspot:hover {
  opacity: 1;
}

.mobile .link-hotspot {
  width: 70px;
  height: 70px;
}

.link-hotspot-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.link-hotspot-tooltip {
  position: absolute;
  left: 100%;
  top: 14px; /* ( 60 - (16 + 2*8) ) / 2 */

  margin-left: 3px;

  font-size: 16px;

  max-width: 300px;

  padding: 8px 10px;

  border-radius: 5px;

  background-color: rgb(58,68,84);
  background-color: rgba(58,68,84,0.8);

  color: #fff;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  cursor: pointer;

  opacity: 0;
  transform: translateX(-8px);
  transition: transform 0.3s,
              opacity 0.3s;
}

.mobile .link-hotspot {
  top: 19px; /* ( 70 - (16 + 2*8) ) / 2 */
}

.no-touch .link-hotspot:hover .link-hotspot-tooltip {
  opacity: 1;
  transform: translateX(0);
}

/* Prevent tooltip from triggering */
.link-hotspot-tooltip {
  pointer-events: none;
}
.no-touch .link-hotspot:hover .link-hotspot-tooltip {
  pointer-events: all;
}

/* Fallback mode without pointer-events (IE8-10) */
.tooltip-fallback .link-hotspot-tooltip {
  display: none;
}
.no-touch .tooltip-fallback .link-hotspot:hover .link-hotspot-tooltip {
  display: block;
}

/* Info hotspot */

.info-hotspot {
  line-height: 1.2em;
  opacity: 0.9;
  transition: opacity 0.2s 0.2s;
}

.no-touch .info-hotspot:hover {
  opacity: 1;
  transition: opacity 0.2s;
}

.info-hotspot.visible {
  opacity: 1;
}

.info-hotspot .info-hotspot-header {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: rgb(103,115,131);
  cursor: pointer;
  transition: width 0.3s ease-in-out 0.5s,
              border-radius 0.3s ease-in-out 0.5s;
}

.mobile .info-hotspot .info-hotspot-header {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.desktop.no-touch .info-hotspot .info-hotspot-header:hover {
  width: 260px;
  border-radius: 5px;
  transition: width 0.3s ease-in-out,
              border-radius 0.3s ease-in-out;
}

.desktop .info-hotspot.visible .info-hotspot-header,
.desktop.no-touch .info-hotspot.visible .info-hotspot-header:hover {
  width: 260px;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: width 0.3s ease-in-out,
              border-radius 0.3s ease-in-out;
}

.info-hotspot .info-hotspot-icon-wrapper {
  width: 40px;
  height: 40px;
}

.mobile .info-hotspot .info-hotspot-icon-wrapper {
  width: 50px;
  height: 50px;
}

.info-hotspot .info-hotspot-icon {
  width: 90%;
  height: 90%;
  margin: 5%;
}

.info-hotspot .info-hotspot-title-wrapper {
  position: absolute;
  left: 40px;
  top: 0;
  width: 0;
  height: 40px;
  padding: 0;
  overflow: hidden;
  transition: width 0s 0.4s,
              padding 0s 0.4s;
}

.desktop .info-hotspot.visible .info-hotspot-title-wrapper,
.desktop.no-touch .info-hotspot .info-hotspot-header:hover .info-hotspot-title-wrapper {
  width: 220px;
  padding: 0 5px;
  transition: width 0s 0.4s,
              padding 0s 0.4s;
}

.info-hotspot .info-hotspot-title-wrapper:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.info-hotspot .info-hotspot-title {
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: text;
  user-select: text;
}

.info-hotspot .info-hotspot-close-wrapper {
  position: absolute;
  left: 260px;
  top: 0;
  height: 40px;
  width: 40px;
  border-top-right-radius: 5px;
  background-color: rgb(78,88,104);
  visibility: hidden;
  transform: perspective(200px) rotateY(90deg);
  transform-origin: 0 50% 0;
  transition: transform 0.3s 0.3s,
              visibility 0s 0.6s;
}

.desktop .info-hotspot.visible .info-hotspot-close-wrapper {
  visibility: visible;
  transform: perspective(200px) rotateY(0deg);
  transition: transform 0.3s,
              visibility 0s 0s;
}

.info-hotspot .info-hotspot-close-icon {
  width: 70%;
  height: 70%;
  margin: 15%;
}

.info-hotspot .info-hotspot-text {
  position: absolute;
  width: 300px;
  height: auto;
  max-height: 200px;
  top: 40px;
  left: 0;
  padding: 10px;
  background-color: rgb(58,68,84);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow-y: auto;
  visibility: hidden;
  /* rotate(90deg) causes transition flicker on Firefox 58 */
  transform: perspective(200px) rotateX(-89.999deg);
  transform-origin: 50% 0 0;
  transition: transform 0.3s,
              visibility 0s 0.3s;
  -webkit-user-select: text;
  user-select: text;
}

.desktop .info-hotspot.visible .info-hotspot-text {
  visibility: visible;
  transform: perspective(200px) rotateX(0deg);
  transition: transform 0.3s 0.3s,
              visibility 0s 0s;
}

/* Info hotspot modal */

.desktop .info-hotspot-modal {
  display: none;
}

.info-hotspot-modal {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 11000 !important;
  background-color: rgba(0,0,0,.5);
  line-height: 1.2em;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out 0.5s,
              visibility 0s 0.7s;
}

.info-hotspot-modal.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-in-out,
              visibility 0s 0s;
}

.info-hotspot-modal .info-hotspot-header {
  position: absolute;
  top: 60px;
  left: 10px;
  right: 10px;
  width: auto;
  height: 50px;
  background-color: rgb(103,115,131);
  background-color: rgba(103,115,131,0.8);
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal.visible .info-hotspot-header {
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal .info-hotspot-icon-wrapper {
  width: 50px;
  height: 50px;
}

.info-hotspot-modal .info-hotspot-icon {
  width: 90%;
  height: 90%;
  margin: 5%;
}

.info-hotspot-modal .info-hotspot-title-wrapper {
  position: absolute;
  top: 0;
  left: 50px;
  right: 50px;
  width: auto;
  height: 50px;
  padding: 0 10px;
}

.info-hotspot-modal .info-hotspot-title-wrapper:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.info-hotspot-modal .info-hotspot-title {
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: text;
  user-select: text;
}

.info-hotspot-modal .info-hotspot-close-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: rgb(78,88,104);
  background-color: rgba(78,88,104,0.8);
  cursor: pointer;
}

.info-hotspot-modal .info-hotspot-close-icon {
  width: 70%;
  height: 70%;
  margin: 15%;
}

.info-hotspot-modal .info-hotspot-text {
  position: absolute;
  top: 110px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 10px;
  background-color: rgb(58,68,84);
  background-color: rgba(58,68,84,0.8);
  overflow-y: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  -webkit-user-select: text;
  user-select: text;
}

.info-hotspot-modal.visible .info-hotspot-text {
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0.4s;
}

/* View control buttons */

.viewControlButton {
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: rgb(103,115,131);
  background-color: rgba(103,115,131,0.8);
}

.view-control-buttons .viewControlButton {
  display: block;
}

/* Hide controls when width is too small */
@media (max-width: 600px) {
  .view-control-buttons .viewControlButton {
    display: none;
  }
}

.viewControlButton .icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
}

/* Center is at margin-left: -20px */
.viewControlButton-1 {
  margin-left: -145px;
}
.viewControlButton-2 {
  margin-left: -95px;
}
.viewControlButton-3 {
  margin-left: -45px;
}
.viewControlButton-4 {
  margin-left: 5px;
}
.viewControlButton-5 {
  margin-left: 55px;
}
.viewControlButton-6 {
  margin-left: 105px;
}

@media(min-width: 750px) {
  .tablet-device {
    padding-top: 15px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .tablet-navbar {
    padding-top: 25px !important;
  }
}

.order-update-steps {
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.order-update-step {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 40%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  z-index: 1;
}

.order-update-step-line {
  width: 5px;
  display: flex;
  align-items: center;
  position: absolute;
}

.order-update-step-line.aprobado {
  height: 100%;
  bottom: 50%;
}

.order-update-step-line.recibido {
  height: 100%;
  bottom: 50%;
}

._3eULf > div:first-child {
    display: none;
}
.estado__btn {
  font-size: 12px;
}

.info__labels {
}

/* sm */
@media (min-width: 576px) { 
  .estado__btn {
    font-size: 14px;
  }
}

/* md */
@media (min-width: 768px) { 
  .estado__btn {
    font-size: 16px;
  }
}


/* lg */
@media (min-width: 992px) {  }


/* xl */
@media (min-width: 1200px) {  }


/* xxl */
@media (min-width: 1400px) {  }
.section__title {
  font-size: 14px;
  max-width: 80px;
}

.section__opts {
  max-width: 140px;
}


.section__btns {
  width: 35px;
  height: 35px;
  font-size: 10px;
}

/* sm */
@media (min-width: 576px) { 
  .section__title {
    font-size: 16px;
    max-width: 150px;
  }

  .section__opts {
    max-width: 150px;
  }

  .section__btns {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

/* md */
@media (min-width: 768px) { 

}


/* lg */
@media (min-width: 992px) {  }


/* xl */
@media (min-width: 1200px) {  }


/* xxl */
@media (min-width: 1400px) {  }
.bottom-navbar{
    z-index: 9999 !important;
    background-color: #fff !important;
}

.hidden-tabs {
    bottom: 35px;
    left: 79vw;
    right: 0;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    padding: 50px;
    z-index: 99999;



  }
  
  .overlay {
    position: fixed;
    top: -75px;
    left: 0;
    width: 100%;
    height: 98%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

.hidden-tabs .tab {
  width: 60px !important;
  height: 60px !important;
  margin: 5px 0;
  border-radius: 10%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #333;
  background-color: #f8f9fa;
  position: relative;
  bottom: auto;
}

.hidden-tabs .tab:hover {
  background-color: #17a2b8;
  color: #000;
}

.hidden-tabs .tab-selected {
  background-color: #fff;
  color: #000;
}

.hidden-tabs.show {
  bottom: 0;
  top: auto;
}


/* animacion */

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation: fadeInUp 0.5s ease-out;
}

.proveedores-table-container {
  width: 100%;
  background-color: white;
  padding: 15px;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow-x: auto;
}

.proveedores-table {
  width: 100%;
  min-width: 1300px;
}

.proveedores-table-container table tr {
  vertical-align: middle;
  text-align: center;
}

.proveedor-card .card-title {
  max-width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .proveedor-card .card-title {
    max-width: 100%;  
  }
}
.modal-content-custom {
    border-radius: 10px !important;
  }
