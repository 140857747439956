@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --shadow-original: 0 0 0 0.2rem rgba(252, 103, 103, 0.4);
  --shadow: 0 0 0 0.2rem rgba(189, 109, 79, 0.4);
  user-select: text !important;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
div,
li,
p,
a,
ion-label {
  font-family: "Poppins", sans-serif;
}

h1 {
  margin-bottom: 0px;
}

body {
  background-color: var(--light);
}

body.backdrop-no-scroll {
  width: 100vw;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

/* styles.css */

.icon-wrapper {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper-lg {
  width: 125px;
  height: 125px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
}

.custom-ionitem::part(native) {
  background-color: #fcf8f7;
}

.custom-icon {
  object-fit: contain;
  display: inline-block;
  max-width: 25px;
  width: 25px;
  height: 25px;
}

.franja {
  background-color: #eeeff1;
  height: 100px;
  margin-left: -2vw;
  margin-top: -1.2vw;
  width: 104.5%;
}

.folder-card img {
  z-index: 5;
}

.folder-options {
  position: absolute;
  top: 20px;
  right: 35px;
  z-index: 200;
}

.folder-btn {
  top: 35px;
  right: 15px;
  z-index: 200;
  position: absolute;
}

.folder-title-container {
  align-items: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  top: 0px;
}

.folder-title {
  font-size: 1.75rem;
  text-align: center;
  z-index: 10;
}

.border-0 {
  border: none !important;
}

.border-0 {
  border: none !important;
}

/* adjunto card */

.btn-options-container {
  transform: rotate(90deg) !important;
}

.btn-options-container i.fa {
  margin-left: 0px;
}

.cardOption {
  margin-top: -170px;
  margin-left: 10px;
}

.card {
  border: 0px;
  border-radius: 10px !important;
}

.adjunto-card,
.adjunto-card * {
  border-radius: 5px;
}

.adjunto-options {
  top: 5px;
  right: 30px;
  z-index: 200;
  position: absolute;
  border-radius: 10px;
}

.adjunto-card .btn-options {
  position: absolute;
  z-index: 15;
  right: 0px;
}


.adjunto-card-footer {
  border-radius: 0px 0px 5px 5px;
  background-color: white;
  padding-bottom: 15px;
  position: absolute;
  padding-top: 15px;
  max-width: 100%;
  width: 100%;
  z-index: 10;
  bottom: 0px;
}

.adjunto-card-title {
  width: 100%;
  height: 45px;
  text-overflow: ellipsis;
}

.adjunto-card-img {
  z-index: 1;
  width: 100%;
  height: 260px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.small .user-item__name {
  font-size: 12px;
}

.user-item__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.react-pdf__Document {
  overflow: scroll;
  height: 100%;
}

.view-container {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .view-container {
    height: calc(100vh - 100px);
  }
}

#adjunto-view,
#adjunto-view * {
  z-index: 1100;
}

#adjunto-view .modal-content {
  background-color: white;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.text-overflow {
  max-width: 100%;
  max-height: 75px;
  text-overflow: ellipsis;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  -webkit-user-select: text;
  user-select: text;
}

input[type="file"]:not(.form-control) {
  padding: 40px 0 0 0;
  height: 10px;
  width: 50px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: "Montserrat", sans-serif !important;
}

/* Waves */

.wave {
  position: absolute;
  bottom: -10;
  width: 300%;
  height: 20vh;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {

  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }

  50% {
    transform: translate3d(0, 5px, 0);
  }
}

/* Tags */
.custom-tooltip {
  --bs-tooltip-width: 250px;
}

a:not(.btn) {
  width: 100%;
  display: block;
  margin: auto;
}

a:hover:not(.text-decoration) {
  color: inherit;
  text-decoration: none;
}

a:focus {
  color: inherit;
  background-color: inherit;
}

tbody,
td,
tr {
  border: 0px !important;
}

td {
  padding-top: 12px;
  align-items: center;
  padding-bottom: 12px;
}

/* General */

.show-tablet {
  display: none;
}

.show-mobile-flex {
  display: none;
}

.show-mobile {
  display: none;
}

.obra-details {
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
}

.obra-details h5 {
  font-size: 1rem;
}

.nav-container {
  padding-top: 1.5rem;
}

.nav-item {
  transition: all 0.5s;
  padding: 0.15rem 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
}

.nav-item.selected {
  font-weight: bold;
}

td.rubro {
  min-width: 500px;
  max-width: 500px;
}

td.ponderacion {
  text-align: right;
  max-width: 60px;
  min-width: 60px;
}

td.destajos {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1rem;
}

td.buttons {
  min-width: 150px;
}

.sidebar {
  top: 0;
  left: 0;
  z-index: 20;
  width: 200px;
  height: 100%;
  overflow: auto;
  position: fixed;
  padding-top: 6px;
  overflow-x: hidden;
  background-color: #fff;
  transition: all 0.3s ease;
}


.sidebar.closed {
  width: 100px;
}

.profile-image {
  width: 50px;
  height: 50px;
  margin-bottom: 26px;
  border-radius: 50%;
  object-fit: contain !important;
}

.profile-image.large {
  width: 80px;
  height: 80px;
  margin-left: -30px;
  margin-bottom: 16px;
}

.toggle-menu-container {
  width: 70%;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: -20px;
  background-color: #ffffff;
  padding: 10px 15px;
  cursor: pointer;
}

.sidebar.closed .toggle-menu-container {
  height: 48px;
}

.sidebar.closed .toggle-menu {
  display: inline;
  position: absolute;
  right: -10px;
  justify-content: center;
  margin-left: -30px;
}

.menu-tab {
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
}

.menu-tab-open {
  width: 150px;
  height: 50px;
  margin: auto;
  display: block;
}

.menu-content {
  align-items: center;
  margin-top: 16px;
  width: 100%;
}

.toggle-menu-text {
  margin: 0;
}

.sidebar.open .toggle-menu {
  margin-left: auto;
}

.sidebar.open .menu-item {
  display: flex;
  align-items: center;
  margin-top: 0px !important;
}

.sidebar.closed .menu-item {
  display: flex;
  align-items: center;
  margin-top: 0px !important;
}

.menu-icon svg {
  width: 25px;
  height: 25px;
}

.menu-item:hover {
  border-radius: 10px;
  background-color: #db8c63 !important;
  color: white;
}

.menu-item:hover .menu-icon {
  color: white;
}

.menu-item:hover .menu-icon svg {
  fill: white;
}

.menu-item.active {
  border-radius: 10px;
  background-color: #db8c63 !important;
  color: white;
}

.menu-item.active .menu-icon {
  color: white;
}

.menu-item.active .menu-icon svg {
  fill: white;
}

.info-card .card-title {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 4px;
}


.title {
  display: none;
}

/* PLANTILLA */
.fondoIndividualesPlantilla {
  background-color: #f8f9fa;
  border-radius: 8px;
}

.titlePlantilla {
  font-weight: 600;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
}

.titleInputPlantilla {
  letter-spacing: .5px;
  font-weight: 600;
  color: #c5c5c5;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.titleCuadradoPlantilla {
  letter-spacing: .5px;
  color: #b86d4f;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.fondoIndividualesPlantilla {
  color: #ababab;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  height: 160px;
}

/* usuarios */
.table-usuarios {
  border-style: hidden !important;
}

/* pagination */
.pagination li.rounded {
  border-radius: 10px !important;
}

.pagination li:not(:last-child) {
  margin-right: 5px;
}

.pagination li:first-child,
.pagination li:last-child {
  display: none;
}

.pagination .page-link {
  width: 40px;
  font-weight: 600;
}

#navbar {
  z-index: 20;
  margin-left: 100px;
  width: calc(100vw - 100px);
  box-shadow: .5rem .5rem 1rem -.2rem rgba(var(--bs-body-color-rgb), .15) !important;
  transition: all 0.3s ease;
}


#content {
  overflow: hidden;
  z-index: 5;
  width: 100vw;
  min-height: 100vh;
  padding-top: 65px;
  margin-left: 100px;
  padding-bottom: 2rem;
  width: calc(100vw - 100px);
}

@media (min-width: 768px) {
  #content {
    width: calc(100vw - 100px);
    padding-bottom: 0 !important;
  }
}

@media (max-width: 1200px) {
  .chart-wrapper {
    margin-bottom: -1.5rem;
  }

  td.destajos {
    padding-left: 0px;
  }

  h1 {
    font-size: 2rem;
  }

  .folder-title {
    font-size: 1.25rem;
  }

  .cronograma-desktop .h4 {
    font-size: 1.25rem;
  }

  .cronograma-desktop .h5 {
    font-size: 1rem;
  }

  .obra-details {
    margin-top: 1.5rem;
  }

  .nav-container {
    padding-top: 0px;
  }

  .item-overflow-ellipsis {
    overflow: hidden;
    max-height: 60px;
    text-overflow: ellipsis;
  }

  img.img-obra {
    width: 75px;
    height: 75px;
    margin: auto;
    display: block;
    object-fit: cover;
  }

  .hide-tablet {
    display: none;
  }

  .show-tablet {
    display: inherit;
  }
}

/* Elements */

.react-pdf__Page__canvas {
  margin: auto;
  width: 100%;
}

.bitacoraImage {
  object-fit: cover;
  max-width: 35px;
  max-height: 35px;
  height: 35px;
}

.bitacora-container {
  bottom: 0 !important;
  position: "fixed" !important;
  max-width: "100%";
  width: "100%";
}

.bitacora-container * {
  max-height: 50px;
}


.bitacora-input:focus {
  box-shadow: none !important;
}

.progreso-container {
  margin-top: -50px;
}


.folder-caption {
  position: relative;
  top: -140px;
  width: 100%;
}

.text-link:hover {
  cursor: pointer;
}

.folder-row:hover {
  cursor: pointer;
}


.Cal__Weekdays__day {
  color: #6c757d;
}

.button {
  transition: 0.5s;
}

.button:hover {
  transform: scale(1.05);
}

.outline-accent {
  padding: 15px 35px;
}

.tour-card {
  transition: all 0.5s;
}

.tour-card:hover {
  cursor: pointer;
}

@media (min-width: 750px) {
  #bitacora-input {
    width: 98%;
    bottom: 15px !important;
  }

}


@media (max-width: 576px) {
  .progreso-container {
    margin-top: 0px;
  }

  div.chart-wrapper {
    margin-left: -2.5rem !important;
  }

  .btn-text {
    padding-left: 0px;
    padding-right: 0px;
    text-align: center !important;
  }

  .login {
    min-height: 100vh;
  }

  .login-title {
    padding: 0px !important;
  }
}

@media (max-width: 576px) {
  div.chart-wrapper {
    margin-top: 0.5rem !important;
  }
}

.colorBox {
  padding: 0.5px 12px;
  border-radius: 5px;
  margin-right: 5px;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-progress-fill {
  fill-opacity: 0.35;
}

.circle-ontime-fill {
  fill-opacity: 0.35;
}

.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}

/* CheckBox */

.checkbox {
  width: 25px;
  height: 25px;
}

.checked:disabled {
  opacity: 1;
}

.checked:not(:disabled):not(.disabled):active {
  box-shadow: var(--shadow);
}

.checked:not(:disabled):not(.disabled):active:focus {
  box-shadow: var(--shadow);
}

.unchecked:hover {
  box-shadow: var(--shadow);
}

.unchecked:disabled {
  opacity: 1;
}

.unchecked:disabled:hover {
  box-shadow: none;
}

.unchecked:not(:disabled):not(.disabled):active {
  box-shadow: var(--shadow);
}

.unchecked:not(:disabled):not(.disabled):active:focus {
  box-shadow: var(--shadow);
}

/* Attributes */
.text-underline {
  text-decoration: underline !important;
}

.shadow-md {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.br-10 {
  border-radius: 10px;
}

.round5 {
  border-radius: 5px;
}

.round10 {
  border-radius: 10px;
}

.round15 {
  border-radius: 15px;
}

.round20 {
  border-radius: 20px;
}

.round25 {
  border-radius: 25px;
}

.br-20 {
  border-radius: 20px;
}

.bold {
  font-weight: 800;
}

ul.list-style-none {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bottomRound {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

/* Layout */
.no-padding {
  padding: 0px !important;
}

.no-margin {
  margin: 0px !important;
}

.mx-n-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.pr-0 {
  padding-right: 0px;
}

.ps-0 {
  padding-left: 0px;
}

.mt-center {
  margin-top: 15vh;
}

.pt-center {
  padding-top: 18vh;
}

.pt-30 {
  padding-top: 25vh;
}

.img-obra {
  height: 150px;
  max-width: 150px;
  object-fit: contain;
  border-radius: 300px;
  box-shadow: 0 0 15px rgba(0, 0, 0, .2);
}

.makeStyles-appBar-1 {
  box-shadow: none !important;
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-menu {
  position: absolute;
  width: 100%;
  right: 5px;
  top: 60px;
}

.offcanvas {
  z-index: 15 !important;
  transition: all 0.5s;
}

.offcanvas * {
  transition: all 0.5s;
}

.offcanvas-header {
  margin-top: 80px;
}

.max-h-auto {
  max-height: unset !important;
}

.list-style-type-none {
  list-style-type: none;
  margin-bottom: 0px;
  padding-left: 0px;
}

.list-style-type-none li {
  text-align: left;
}

.list-item {
  padding: 6px 12px;
  font-size: 14px;
}

.list-item i {
  font-size: 12px;
  margin-right: 10px;
}

.estimaciones-actions {
  width: 215px;
  right: 15px;
  top: 40px;
}

.menu-card {
  height: 35vh; 
}

.concepto-generador-actions {
  position: absolute;
  width: 100px;  
  top: -1px;
}

@media (max-width: 767px) {
  .permisos-container {
    padding: 0px !important;
  }

  .btn-tab {
    font-size: 12px !important;
    text-transform: uppercase;
  }

  .chart-wrapper {
    margin-left: -1rem !important;
    margin-top: -1rem !important;
  }

  .sidebar {
    display: none !important;
  }

  .navbar {
    display: none;
  }

  .title {
    display: flex;
  }

  #content {
    width: 100vw;
    /* margin-top: ; */
    margin-left: 0px;
    padding-top: 70px;
  }

  .avance-picker {
    max-width: 100px;
  }

  .files-name {
    margin-top: 36px;
  }

  .form-control {
    font-size: 0.8rem;
  }

  .checkbox {
    font-size: 8px;
    width: 15px;
    height: 15px;
  }

  .adjunto-card-footer {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .adjunto-card-img {
    height: 200px;
  }

  .folder-btn {
    top: 15px;
    right: 0px;
    position: absolute;
  }

  .folder-title {
    font-size: 1rem;
  }

  .modal.modal-lg {
    left: 0px;
  }

  .mt-center {
    margin-top: 10vh;
  }

  .pt-center {
    padding-top: 5vh;
  }

  .hide-mobile {
    display: none;
  }

  .show-mobile-flex {
    display: flex;
  }

  .show-mobile {
    display: block;
  }

  .bottom-navbar {
    display: block !important;
  }

  div,
  p,
  span,
  li,
  b {
    font-size: 14px;
  }

  .mobile-left {
    text-align: left !important;
  }

  .mobile-center {
    text-align: center !important;
  }

  .tab {
    transition: all 0.5s;
    border-top: 4px solid transparent;
  }

  .bitacoraImage {
    object-position: 100% 0;
  }

  .bitacora-container {
    width: "95%";
  }

  .outline-accent {
    padding: 15px;
    margin-bottom: 15px;
  }

  #sidebar {
    display: none;
  }
}

/* Colors */

.toggle {
  cursor: pointer;
}

.hover-primary:hover {
  cursor: pointer;
}

.tabla-preciario td.nombre-concepto {
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 300px !important;
  max-width: 300px !important;
}

.partida-preciario:not(.extra) {
  background-color: #c8f0e6;
  border-bottom: 1px solid #ced4da !important;
  cursor: pointer;
}

.concepto-aditivo {
  background-color: #fffaea;
}

.concepto-extra {
  background-color: rgb(255, 239, 239);
}

.extra {
  background-color: rgb(255, 239, 239);
}

.border-card {
  border: 0.5px solid #f3f3f3;
}

.min-100 {
  min-width: 100px;
}

.min-120 {
  min-width: 120px;
}


.concepto-row td {
  padding-right: 0px;
}

ul.unstyled {
  padding-left: 0px;
  list-style-type: none;
}

.nav-tabs .nav-link {
  max-width: 50px !important;
  margin-left: 0px;
  margin-right: 5px;
}



.bottom-navbar {
  z-index: 1 !important;
  position: fixed;
  display: none;
  /* z-index: 1032; */
  bottom: 0px;
  width: 100%;
}

.bottom-navbar i {
  font-size: 24px;
}

td {
  vertical-align: middle;
  padding-left: 6px;
  padding-right: 6px;
}

.table>:not(caption)>*>* {
  border-bottom: 0px;
}

.th-border-0 th {
  border: 0px;
}

.tabla-preciario td,
.tabla-preciario th {
  min-width: 100px;

}

.tabla-preciario th {
  font-size: 14px;
}

.td-short {
  max-width: 50px;
}

.mw-50-px {
  max-width: 50px;
  width: 50px;
}

.user-card {
  max-width: 200px;
  max-height: 100px;
}

.user-card img {
  object-fit: contain;
  max-height: 35px;
}

div#entradas {
  height: 75vh;
  overflow-y: scroll;
}

input#fileInput {
  position: absolute;
}

.hidden {
  display: none;
}

.alert {
  z-index: 1000;
}

.login-container {
  margin: auto;
  display: block;
  max-width: 600px;
}

.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-login {
  background-image: url("../assets/fondo_login.png");
}

.fw-400 {
  font-weight: 400;
}

.color {
  height: 35px;
  width: 35px;
  border-radius: 70px;
  display: block;
}

.thumbnail {
  max-width: 50px;
  object-fit: contain;
}

.thumbnail.sm {
  max-width: 35px;
}

.btn-tab {
  font-size: 14px;
  font-weight: 400;
  min-width: 100px;
  text-transform: uppercase;
}

.chart-wrapper {
  margin-left: -2rem;
  margin-top: -1rem;
  margin-bottom: -1.5rem;
  width: calc(100% + 3rem) !important;
}

.activity {
  height: 80px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge {
  font-weight: 600;
  font-size: 0.85rem !important;
}

.progress-chart {
  z-index: 1;
}

.progress,
.progress-bar {
  display: block !important;
}

.options-container {
  position: absolute;
  top: 10px;
  right: 40px;
  z-index: 200;
}

#login-logo {
  max-width: 150px;
}

.picker {
  min-width: 150px;
  max-width: 150px;
  width: 150px;
}

.avance-picker {
  width: 100%;
  margin-left: 0px;
  max-width: 200px;
  border-radius: 10px;
}

.br-0 {
  border-radius: 0px;
}

.br-10 {
  border-radius: 10px;
}

.avance-picker .button-left {
  border-radius: 6px 0px 0px 6px !important;
}

.avance-picker .button-right {
  border-radius: 0px 6px 6px 0px !important;
}

.transition,
.transition * {
  transition: all 0.5s;
}

.cronograma-mobile {
  height: calc(100% - 75px);
}

.estimacion-resumen tr, .estimacion-resumen td {
  border: 1px solid rgba(0,0,0,0.1) !important;
}

.badge.small {
  font-size: 12px !important;
}

.table.border td.border-0 {
  border: 0px !important;
}

.preciario td, table.border td:not(.border-0), table.border th {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.firma-mobile svg {
  height: 100px;
}

#preciario-mobile {
  margin-left: -15px;
}

.adjunto-mensaje {
  max-height: 250px;
  object-fit: contain;
}

@media (max-width: 767px) {
  .adjunto-mensaje {
    max-height: 200px;
  }
}

@media print {
  body{
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .sidebar {
    display: none !important;
  }
  #content {
    width: 100vw;
    margin-left: 0px !important;
  }
  #report-header {
    display: none;
  }
  #entradas.h-100 {
    height: auto !important;
    overflow-y: visible !important;
  }
  #bitacora-input {
    display: none;
  }
  #obra-view.h-100 {
    height: auto !important;
    overflow-y: visible !important;
  }
  .h-100 {
    height: auto !important;
  }
  .navbar-toggler {
    display: none;
  }
  .fa-chevron-left {
    display: none;
  }
  .adjunto-mensaje {
    max-height: 100px;
    object-fit: contain;
  }
  .msg-card.text-white {
    color: #000 !important;
  }
  .msg-card {
    padding: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
  }
  .navbar {
    display: none !important;
  }
  .navbar .col-8 {
    height: auto !important;
  }
  .shadow {
    box-shadow: none;
  }
  #obra-view, #obra-view .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
  .cronograma-mobile .item-inner {
    padding-right: 0px !important;
  }

}