.section__title {
  font-size: 14px;
  max-width: 80px;
}

.section__opts {
  max-width: 140px;
}


.section__btns {
  width: 35px;
  height: 35px;
  font-size: 10px;
}

/* sm */
@media (min-width: 576px) { 
  .section__title {
    font-size: 16px;
    max-width: 150px;
  }

  .section__opts {
    max-width: 150px;
  }

  .section__btns {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

/* md */
@media (min-width: 768px) { 

}


/* lg */
@media (min-width: 992px) {  }


/* xl */
@media (min-width: 1200px) {  }


/* xxl */
@media (min-width: 1400px) {  }