/* styles.css */

  .inputWithIcon {
    position: relative;
    margin-top: -100px;
    margin-left: 30px;
    width: 84%;
  }
  
  .inputWithIcon input {
    height: 50px;
    border: 3px solid #db8c63;
    box-sizing: border-box;
    border-radius: 10px;
  }
  
  .inputWithIcon input::placeholder {
    color: gray;
  }
  
  .inputWithIcon .sendButton {
    position: absolute;
    right: 8px;
    top: 0;
    margin-top: 7px;
    border-radius: 10px;
    height: 36px;
    background-color: #db8c63;
    border: none;
    color: white;
    font-size: 1rem;
    padding: 0 10px;
    cursor: pointer;
  }
  
  .inputWrapper {
    display: flex;
    align-items: center;
  }
  
  .inputField {
    height: 50px;
    border-radius: 5px 0 0 5px;
    border-right: none;
  }
  
  .sendButton {
    height: 50px;
    border-radius: 0 5px 5px 0;
  }
  

.calendarContainer {
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: #f8f9fa;
  }

