.order-update-steps {
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.order-update-step {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 40%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  z-index: 1;
}

.order-update-step-line {
  width: 5px;
  display: flex;
  align-items: center;
  position: absolute;
}

.order-update-step-line.aprobado {
  height: 100%;
  bottom: 50%;
}

.order-update-step-line.recibido {
  height: 100%;
  bottom: 50%;
}
